@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Protest+Guerrilla&display=swap');
.sun_shadow{
    box-shadow: -40px -10px 20px -2px rgba(8, 112, 184, 0.7);

}

.Logo-font-family {
    font-family: "Protest Guerrilla", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  link[rel="icon"] {
    width: 48px;
    height: 48px;
  }
